<template>
  <a-modal
    :visible="visible"
    :title="title"
    :width="700"
    @cancel="handleCancel"
    @ok="submit"
    :confirmLoading="confirmLoading"
  >
    <a-form-model
      :model="form"
      ref="ruleForm"
      layout="horizontal"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="插件名称" ref="name" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item ref="type" prop="type" label="插件类型">
        <a-select v-model="form.type" placeholder="请输入插件类型">
          <a-select-option :value="0">URL</a-select-option>
          <a-select-option :value="1">HOST</a-select-option>
          <a-select-option :value="2">DOMAIN</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="上传插件" prop="code" ref="code">
        <a-upload
          name="file"
          :multiple="false"
          :beforeUpload="beforeUpload"
          v-model="form.code"
          action=""
        >
          <a-button> <a-icon type="upload" />点击上传 </a-button>
        </a-upload>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addPlugin, pluginDetail, changePlugin } from "@/api/plugin";
export default {
  data() {
    return {
      id: 0,
      title: "",
      visible: false,
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: "",
        type: 0,
        code: "",
      },
      rules: {
        name: [
          { required: true, message: "插件名称不能为空", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择插件类型", trigger: "blur" }],
        // code: [{ required: true, message: "请选择需要上传的插件", trigger: "blur" }],
      },
    };
  },
  methods: {
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          if (this.id === 0) {
            const formData = new FormData();
            formData.append("name", this.form.name);
            formData.append("type", this.form.type);
            formData.append("code", this.form.code);
            addPlugin(formData)
              .then((res) => {
                if (res.status === 201) {
                  this.visible = false;
                  this.$refs["ruleForm"].resetFields();
                  this.$message.success(res.data.msg);
                  this.$parent.$parent.fetch();
                } else {
                  this.$message.error(res.data.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          } else {
            const formData = new FormData();
            formData.append("name", this.form.name);
            formData.append("type", this.form.type);
            formData.append("code", this.form.code);
            changePlugin(this.id, formData)
              .then((res) => {
                var data = res.data;
                var code = data.code;
                var msg = data.msg;
                if (code === 0) {
                  this.$message.error(msg);
                } else {
                  this.visible = false;
                  this.$refs["ruleForm"].resetFields();
                  this.$message.success(msg);
                  this.$parent.$parent.fetch();
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
          }
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
      this.$refs["ruleForm"].resetFields();
    },
    beforeUpload(file) {
      this.form.code = file;
      return false;
    },
    fetch(id = 0) {
      this.visible = true;
      if (id !== 0) {
        this.id = id;
        pluginDetail(this.id)
          .then((res) => {
            let result = res.data;
            this.form.name = result.name;
            this.form.type = result.type;
            this.title = "插件更改-" + this.form.name;
          })
          .catch((err) => {
            this.$message.error(err.toString());
          });
      } else {
        this.title = "插件添加";
      }
    },
  },
};
</script>
