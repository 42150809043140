<template>
  <a-card :bordered="false" class="content" style="width: 100%">
    <a-button type="primary" icon="plus" @click="add_plugin">新增插件</a-button>
    <a-input-search
      placeholder="全局搜索"
      style="width: 300px; float: right"
      v-model="globalSearch"
      @search="onSearch"
    />
    <a-table
      ref="table"
      style="width: 100%; margin-top: 10px;"
      size="small"
      :loading="loading"
      :rowKey="(row) => row.id"
      :columns="columns"
      :data-source="data"
      showPagination="auto"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <span slot="type" slot-scope="text">
        {{ text | typePlugin }}
      </span>
      <span slot="create_time" slot-scope="text">
        {{ changeTime(text) }}
      </span>
      <span slot="update_time" slot-scope="text">
        {{ changeTime(text) }}
      </span>
      <span slot="status" slot-scope="text, plugin">
        <a-tag @click="changeStatus(plugin)" :color="text | pluginStatusColor">{{
          text | pluginStatusText
        }}</a-tag>
      </span>
      <span slot="action" slot-scope="text">
        <a-button
          type="primary"
          size="small"
          style="margin-right: 8px"
          @click="edit_plugin(text.id)"
        >编辑</a-button>
        <a-button
          type="danger"
          size="small"
          @click="delete_plugin(text.id)"
        >删除</a-button>
      </span>
      <p slot="expandedRowRender" slot-scope="text" style="margin: 0;">
        <pre>{{ text.code }}</pre>
      </p>
    </a-table>
    <plugin-add ref="pluginAdd" />
  </a-card>
</template>

<script>
import PluginAdd from "./modules/PluginAdd";
import { pluginList, deletePlugin, changePluginStatus } from "@/api/plugin";
import { changeTime } from "@/libs/utils";
import consts from "@/constants/plugin";
import { Modal } from "ant-design-vue";
export default {
  components: {
    PluginAdd,
  },
  data() {
    this.changeTime = changeTime;
    return {
      loading: false,
      data: [],
      globalSearch: "",
      columns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          scopedSlots: { customRender: "id" },
          width: 80,
          sorter: true,
        },
        {
          title: "插件名称",
          dataIndex: "name",
          ellipsis: true,
          scopedSlots: { customRender: "name" },
          width: 80,
        },
        {
          title: "插件类型",
          dataIndex: "type",
          ellipsis: true,
          scopedSlots: { customRender: "type" },
          width: 80,
          filters: [
            {
              text: "URL",
              value: 0,
            },
            {
              text: "HOST",
              value: 1,
            },
            {
              text: "DOMAIN",
              value: 2
            },
          ],
          filterMultiple: false,
        },
        {
          title: "状态",
          dataIndex: "status",
          ellipsis: true,
          scopedSlots: { customRender: "status" },
          width: 80,
          filters: [
            {
              text: "下线",
              value: 0
            },
            {
              text: "上线",
              value: 1,
            }
          ],
          filterMultiple: false,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          ellipsis: true,
          scopedSlots: { customRender: "create_time" },
          width: 80,
          sorter: true,
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          ellipsis: true,
          scopedSlots: { customRender: "update_time" },
          width: 80,
          sorter: true,
        },
        {
          title: "操作",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        current: 1,
        pageSize: 10,
        onShowSizeChange: (current, pageSize) => (this.pagination.pageSize = pageSize),
      },
    };
  },
  filters: {
    typePlugin (type) {
      return consts.pluginTypeList[type];
    },
    pluginStatusText (type) {
      return consts.pluginStatusList[type].label;
    },
    pluginStatusColor (type) {
      return consts.pluginStatusList[type].color;
    },
  },
  methods: {
    onSearch () {
      this.fetch();
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      // 过滤
      let filterDic = {};
      for (var i in filters) {
        filterDic[i] = filters[i][0];
      }
      // 排序
      let orderingDic = {};
      if (sorter.order) {
        orderingDic["ordering"] =
          `${sorter.order === "ascend" ? "" : "-"}` + sorter.field;
      }
      this.fetch({...filterDic, ...orderingDic});
    },
    add_plugin() {
      this.$refs["pluginAdd"].fetch();
    },
    edit_plugin(id) {
      this.$refs["pluginAdd"].fetch(id);
    },
    changeStatus(plugin) {
      var id = plugin.id;
      changePluginStatus(id)
        .then((res) => {
          this.$message.success(res.data.msg);
          this.fetch();
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
    },
    delete_plugin(id) {
      Modal.confirm({
        title: "警告",
        content: "您确定要删除此插件吗?",
        onOk: () => {
          deletePlugin(id)
            .then((res) => {
              if (res.status === 204) {
                this.$message.success("删除成功!");
                this.fetch();
              } else {
                this.$message.error("删除失败!");
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        },
        onCancel() {},
      });
    },
    fetch(query = {}) {
      this.loading = true;
      if (this.globalSearch) {
        query["search"] = this.globalSearch;
      }
      query["page_size"] = this.pagination.pageSize;
      query["page"] = this.pagination.current;
      pluginList(query)
        .then((res) => {
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.count);
          this.data = res.data.results;
          this.pagination = pagination;
        })
        .catch((err) => {
          this.$message.error(err.toString());
        });
      this.loading = false;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
